import React, { useState,useEffect,useRef } from 'react';
import {
  Row,
  Col,
} from 'react-bootstrap';
import { Form, FormControl, Table, Pagination, Button, Dropdown, Modal } from 'react-bootstrap';

import DeleteIcon from '@mui/icons-material/Delete';

import EditIcon from '@mui/icons-material/Edit';

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import './../../styles/modelFulscreen.css';
import { addHomePhoto, getApiBaseURL, getHomePhoto } from '../../services';
const renderImages = async (url) => {
    const urls = await photoGetAndPreviews(url.replace(/\\/g, "/"))
          
    return urls;
  };
  const photoGetAndPreviews = async (url) => {
    const serverUrl = getApiBaseURL();
    if (url) {
      const photoUrl = `${serverUrl}/admin/${url}`;
      try {
        const res = await fetch(photoUrl);
        if (!res.ok) {
          throw new Error(`Failed to fetch image: ${res.statusText}`);
        }
        const blob = await res.blob();
        const reader = new window.FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = () => resolve(reader.result);
          reader.onerror = () => reject(new Error("Failed to read file"));
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error("Error fetching or processing the image:", error);
      }
    }
    return null; // Return null if no URL is provided
  };
function Homephoto(){
    const [modelBulk, setModelBulk] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [dataGet,setDataGet]=useState([])
    const [validated, setValidated] = useState(false);
    const [marqueText, setMarqueText] = useState("");
  useEffect((e)=>{
    getHomePhoto().then((response) => {
      if(response.data != undefined)
      {
        setDataGet(response.data)
       
      }
      
    }).catch((error)=>{console.log(error)})
  },[])
  const fileSelectedHandler = (event, status) => {
    if (status === "bulk") {
      const file = event.target.files;
      if (file) {
        for (let fl of file)
            {
                const fileNameParts = fl.name.split('.');
        const fileExtension = fileNameParts[fileNameParts.length - 1];
        if (['jpeg', 'jpg', 'png', 'gif'].includes(fileExtension.toLowerCase())) {
          setSelectedFile(file);
        } else {
          event.target.value = '';
          toast.warn('Please select an image file (.jpeg, .jpg, .png, .gif format)');
        }
            }
        
      } else {
        // No file selected
        setSelectedFile(null); // Clear the selected file
        toast.warn('Please select a file.');
      }
    }
  };
  const fileUploadHandler = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
  
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (!selectedFile || selectedFile.length === 0) {
        toast.warn('Please select a file.');
      } else {
        // Convert FileList to an array to use forEach
        const filesArray = Array.from(selectedFile); // Or use [...selectedFile]
        const formData = new FormData();
  
        // Loop through files
        filesArray.forEach((file) => {
          const fileNameParts = file.name.split('.');
          const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
  
          // Validate file extension
          if (['jpeg', 'jpg', 'png', 'gif'].includes(fileExtension)) {
            formData.append('homeFile', file);
          } else {
            toast.warn('Please select an image file (.jpeg, .jpg, .png, .gif format)');
          }
        });
  
        formData.append('marqueText', marqueText);
  
        try {
          await addHomePhoto(formData).then(response => {
            console.log(response.data);
          }).catch(error => {
            console.error(error);
          });
          toast.success('File uploaded successfully');
          setModelBulk(false);
        } catch (error) {
          console.error('Error uploading the file: ', error);
          toast.error('Error uploading the file');
        }
      }
    }
    setValidated(true);
  };
  const previewFile = (url) => {
    if (url.startsWith('data:image')) {
      openImageInNewTab(url); // Use the openImageInNewTab function for base64 images
    } else {
      previewFile(url); // Call the previewFile function for other files
    }
  };
  return (
    <div>
         <div className="row text-center">
         <h4 style={{fontWeight:"bold"}}> HOME SLIDESHOW PHOTOS</h4></div>
       <div className="row" style={{display:"flex",justifyContent:"space-between"}}>
                        <div className="col-lg-3 mb-4">
                       
                        </div>
                       
                      
                       <div className="col-lg-3 mb-4 no-print" >
                        <div style={{float:"right"}}>
                        <Button variant="success"  onClick={(e)=>{setModelBulk(true)}} >
            Add New Photos
          </Button></div>
                        </div>
                        </div>
               
              
                        {dataGet.length > 0 ? ( <div>
                          <Table striped  hover className="custom-table" id="tablefg">
        <thead>
          <tr>
           <th>S.no</th>
           <th>Marquee Text</th>
           <th>Photos</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {dataGet[0].homePhoto && dataGet[0].homePhoto.map((item, index) => (
            <tr >
              
              <td>{index+1}</td>
              <td>{dataGet[0].marqueText}</td>
              <td><LoadingImageRenderer photos={item.path} previewFile={previewFile} /></td>
            
              <td>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                 
                  <div title="Edit">
                    <EditIcon
                     
                      style={{ fontWeight: "bold", color: "blue", cursor: "pointer" }}
                      title="Edit"
                    />
                  </div>
                  <div title="Delete">
                    <DeleteIcon
                     
                      style={{ fontWeight: "bold", color: "red", cursor: "pointer" }}
                      title="Delete"
                    />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      
        </div>): <div className='text-center'><p style={{border:"1px dashed #000",color:"red"}}>No records found</p></div>}
        <Modal
         size={"md"}
         show={modelBulk}
         onHide={() => setModelBulk(false)}
         aria-labelledby="example-modal-sizes-title-sm"
         centered
       >
         <Modal.Header style={{background:"#c90057"}} onHide={(e)=>setModelBulk(false)} closeButton closeVariant="white">
         <div style={{width:"100%",textAlign:"center"}}>
           <Modal.Title id="m1d" style={{color:"white"}}>
           <span >Add Slide Show Photos</span>
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>
        <Form noValidate validated={validated} onSubmit={fileUploadHandler}>
              <Row>
              <Form.Group as={Col} controlId="formFiles" className="mb-3">
              <Form.Label> Marque Text </Form.Label>
              <Form inline className="mb-2">
        <FormControl
          type="text"
          placeholder="Marque Text"
          
          value={marqueText}
          onChange={(e)=>setMarqueText(e.target.value)}
        />
      </Form> 
     </Form.Group>
     </Row><Row>
                <Form.Group as={Col} controlId="formFiles" className="mb-3">
                  <Form.Label> Photos Upload</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".jpeg, .jpg, .png, .gif"
                    onChange={(e) => fileSelectedHandler(e, "bulk")}
                    multiple
                    isInvalid={!selectedFile}
                  />
                  <Form.Text className="text-muted">
                    Please only select Image files (.jpeg, .jpg, .png, .gif format).
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    Please select a file.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-1">
                  <Button
                    style={{ background: "#7f083c", border: "2px solid #c90057", width: "100%" }}
                   
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Row>
            </Form>
        </Modal.Body>
      </Modal>   
    <ToastContainer
    
    autoClose={3000}
    closeOnClick={false}
    pauseOnHover
     />
    </div>
  );
};
const LoadingImageRenderer = ({ photos, previewFile }) => {
    const [imageUrls, setImageUrls] = useState();
    const [loadingImages, setLoadingImages] = useState(true);
  
    useEffect(() => {
      if (photos) {
        setLoadingImages(true);
        renderImages(photos)
          .then(urls => {
            setImageUrls(urls);
            setLoadingImages(false);
          })
          .catch(error => {
            console.error('Error loading images:', error);
            setLoadingImages(false); // Handle loading state on error
          });
      } else {
        setLoadingImages(false);
      }
    }, [photos]);
  
    return (
      <>
        {loadingImages ? (
          <p>Loading images...</p>
        ) : imageUrls ? (
            <div>
              <img
                src={imageUrls}
                alt={`Image`}
                style={{ width: '200px', height: '50px',cursor:'pointer' }}
                        title='Click Preview!'
                onClick={() => previewFile(imageUrls)}
              />
              
            </div>
        ) : (
          ""
        )}
      </>
    );
  };
  
  const openImageInNewTab = (base64Image) => {
    const byteCharacters = atob(base64Image.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });
    const blobUrl = URL.createObjectURL(blob);
  
    window.open(blobUrl);
  };
export default Homephoto;