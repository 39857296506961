
import "./../../styles/home.css";
import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CryptoJS from "crypto-js";
import  EncryptText from "../../encrypt-decrypt/encrypt";
import {Container,
  Navbar,
  Nav,
  NavDropdown,
  Alert,
  Row,
  Col,
  InputGroup
} from 'react-bootstrap';
import { Form, FormControl, Table, Pagination, Button, Dropdown, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
function AdminHome() {
    const encrpt = EncryptText;
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [modelBulk, setModelBulk] = useState(false);
  const [validated, setValidated] = useState(false);
  const [modelStatus, setModelStatus] = useState("");

  const fileSelectedHandler = (event, status) => {
    if (status === "bulk") {
      const file = event.target.files;
      if (file) {
        for (let fl of file)
            {
                const fileNameParts = fl.name.split('.');
        const fileExtension = fileNameParts[fileNameParts.length - 1];
        if (['jpeg', 'jpg', 'png', 'gif'].includes(fileExtension.toLowerCase())) {
          setSelectedFile(file);
        } else {
          event.target.value = '';
          toast.warn('Please select an image file (.jpeg, .jpg, .png, .gif format)');
        }
            }
        
      } else {
        // No file selected
        setSelectedFile(null); // Clear the selected file
        toast.warn('Please select a file.');
      }
    }
  };

  const fileUploadHandler = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
  
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (!selectedFile) {
        // Handle the case when no file is selected
        toast.warn("Please select a file.");
      } else {
        // Handle the case when file is selected
        const formData = new FormData();
        formData.append("excelFile", selectedFile);
  
        try {
            console.log("selectedFile",selectedFile)
          // Upload the file
          // Example: await uploadFile(formData);
          toast.success("File uploaded successfully");
          setModelBulk(false);
        } catch (error) {
          console.error('Error uploading the file: ', error);
          toast.error("Error uploading the file");
        }
      }
    }
    setValidated(true);
  };

  const adminClick = (event,status) => {
   
    navigate(`/user/dashboard-main?getData=${encrpt(status)}`);
  }

    return (
      <div>
       <div className="row text-center">
                        <div className="col-lg-3 mb-4">
                            <div title="click !" onClick={(e)=>{adminClick(e,'library')}} style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                     Library Desk
                                    <div className="text-white-50 small30"> {'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !"  onClick={(e)=>{adminClick(e,'alumni')}}  style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                Alumni Desk
                                    <div className="text-white-50 small30">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" onClick={(e)=>{adminClick(e,'student')}} style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                    Student's Desk
                                    <div className="text-white-50 small30">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" onClick={(e)=>{adminClick(e,'office')}} style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                    Office Desk
                                    <div className="text-white-50 small">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" onClick={(e)=>{adminClick(e,'guest')}} style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                    Guest ( or ) Visitor's Desk
                                    <div className="text-white-50 small">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" onClick={(e)=>{adminClick(e,'music')}} style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                    Musical Desk
                                    <div className="text-white-50 small">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" onClick={(e)=>{adminClick(e,'teacher')}} style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                    Teacher's Desk
                                    <div className="text-white-50 small">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" onClick={(e)=>{adminClick(e,'class')}} style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                    Classroom Desk
                                    <div className="text-white-50 small">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" onClick={(e)=>{adminClick(e,'slide')}} style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                    Dashboard Photo Add
                                    <div className="text-white-50 small">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" onClick={(e)=>{adminClick(e,'other')}} style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                    Other's Desk
                                    <div className="text-white-50 small">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                    <Modal
         size={"md"}
         show={modelBulk}
         onHide={() => setModelBulk(false)}
         aria-labelledby="example-modal-sizes-title-sm"
         centered
       >
         <Modal.Header style={{background:"#c90057"}} onHide={(e)=>setModelBulk(false)} closeButton closeVariant="white">
         <div style={{width:"100%",textAlign:"center"}}>
           <Modal.Title id="m1d" style={{color:"white"}}>
           <span >{modelStatus}</span>
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>
        <Form noValidate validated={validated} onSubmit={fileUploadHandler}>
              <Row>
                <Form.Group as={Col} controlId="formFiles" className="mb-3">
                  <Form.Label>Bulk Photos Upload</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".jpeg, .jpg, .png, .gif"
                    onChange={(e) => fileSelectedHandler(e, "bulk")}
                    multiple
                    isInvalid={!selectedFile}
                  />
                  <Form.Text className="text-muted">
                    Please only select Image files (.jpeg, .jpg, .png, .gif format).
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    Please select a file.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-1">
                  <Button
                    style={{ background: "#7f083c", border: "2px solid #c90057", width: "100%" }}
                   
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Row>
            </Form>
        </Modal.Body>
      </Modal>   
      <ToastContainer
    
    autoClose={3000}
    closeOnClick={false}
    pauseOnHover
     />  
      </div>
    );
  }
  
  export default AdminHome;