
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import 'bootstrap/dist/css/bootstrap.min.css';
import RouterAll from './router/router';
import './styles/all.min.new.css'
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
function App() {

  return (
    <ThemeProvider
  breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
  minBreakpoint="xxs"
>
  <div >
  <ToastContainer
    
    autoClose={3000}
    closeOnClick={false}
    pauseOnHover
     />
  <RouterAll/>
  </div></ThemeProvider>
  );
}

export default App;
