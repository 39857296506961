import React, { useState, useEffect } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/custom-animations/fall-animation.css';
import 'react-awesome-slider/dist/styles.css';
import { Container, Row, Col } from 'react-bootstrap';
import { getHomePhoto, getApiBaseURL } from '../services';
import './../styles/marque.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

async function fetchImageUrl(path) {
  if (!path) return null;

  const serverUrl = getApiBaseURL();
  const photoUrl = `${serverUrl}/admin/${path.replace(/\\/g, '/')}`;

  try {
    const response = await fetch(photoUrl);
    if (!response.ok) {
      console.error(`Error fetching image: ${response.statusText}`);
      return null;
    }
    return photoUrl; // Return the URL if it's valid
  } catch (error) {
    console.error('Error fetching image:', error);
    return null;
  }
}

function Slidermain() {
  const [imagesWithCaptions, setImagesWithCaptions] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await getHomePhoto();
        if (response.data?.length > 0) {
          const homePhotos = response.data[0].homePhoto || [];
          const marqueText = response.data[0].marqueText || '';
          const images = await Promise.all(
            homePhotos.map(async (photo) => {
              const url = await fetchImageUrl(photo.path);
              return url ? { src: url, caption: marqueText } : null;
            })
          );
          setImagesWithCaptions(images.filter(Boolean)); // Filter out null entries
        }
      } catch (error) {
        console.error('Error fetching home photos:', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col style={{ paddingBottom: '58px' }}>
          <AutoplaySlider
            style={{ height: '350px' }}
            animation="fallAnimation"
            play={true}
            interval={5000}
            organicArrows={true} // Ensure navigation arrows are visible
          >
            {imagesWithCaptions.map((item, index) => (
              <div key={index} data-src={item.src} style={{ borderRadius: '10%' }}>
                <div
                  className="marquee-container"
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  <div className="marquee">
                    <span>{item.caption}</span>
                  </div>
                </div>
              </div>
            ))}
          </AutoplaySlider>
        </Col>
      </Row>
    </Container>
  );
}

export default Slidermain;
